import React, { useState, useRef, useEffect } from "react"
import AppLayout from "../../components/layout"
import style from "./carWashOptions.module.css"
import Arrow from "../../assets/svg/down_arrow_icon.svg"
import { navigate } from "gatsby"
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
} from "antd"
import Additional from "../Additional/Additional"
import CarWashOptionsSmall from "../CarWashOptionSmall/CarWashOptionsSmall"
const CarWashOptions = props => {
  const [form] = Form.useForm()
  const [show, setShow] = useState(false)
  const [long, setLong] = useState()
  const [openInput, setOpenInput] = useState([])
  const accordionHandler = item => {
    setLong(item.id)
    item.id === long ? setShow(!show) : setShow(true)
  }

  const data = [
    {
      id: 1,
      title: "Service Options",
      description: (
        <CarWashOptionsSmall
          setOpenInput={setOpenInput}
          openInput={openInput}
          service={props?.service}
        />
      ),
    },
    {
      id: 2,
      title: "Additional Services",
      description: <Additional sendData={props?.sendData} />,
    },
  ]
  useEffect(() => {
    console.log(props?.sendData)
  }, [])
  const onFinishCarDropOffInspection = values => {
    console.log("Success:", values)
    props.onSubmit(true, { ...values, wash_type_requested: openInput })
  }
  const onFinishFailedCarDropOffInspection = errorInfo => {
    console.log("Failed:", errorInfo)
  }
  useEffect(() => {
    if (props?.editData) {
      const fileds = []

      const sendData = props?.sendData
      sendData?.length > 0 &&
        sendData.forEach(item => {
          //debugger
          if (fileds?.includes(item?.field_name)) {
            form.setFieldsValue({
              [item.field_name]: item.field_value,
            })
          }
        })
    }
  }, [])
  const skipToMyYard = () => {
    const values = form.getFieldsValue()
    props.onSubmit(true, { ...values, wash_type_requested: openInput }, true)
  }
  return (
    <div className={style.car_wash_options_section}>
      <Form
        className="car_drop_form"
        name="basic"
        onFinish={onFinishCarDropOffInspection}
        onFinishFailed={onFinishFailedCarDropOffInspection}
        autoComplete="off"
        layout={"vertical"}
        form={form}
        style={{
          maxWidth: "100%",
          background: "#F6F6F6",
        }}
      >
        <div className={style.contact_section}>
          <div className={style.contact_container}>
            {data.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={
                      show && item.id === long
                        ? style.contact_accordion_active
                        : style.contact_accordion
                    }
                  >
                    <div
                      className={style.contact_accordion_head}
                      onClick={() => accordionHandler(item)}
                      style={{
                        height: show && item.id === long ? "7vh" : "10vh",
                        borderBottom:
                          show && item.id === long ? "1px solid #8B8FAE" : "",
                      }}
                    >
                      <h3>{item.title}</h3>
                      <img
                        src={Arrow}
                        alt="icon forword"
                        style={{
                          transform:
                            show && item.id === long ? "rotate(180deg)" : "",
                        }}
                      />
                    </div>
                    <div
                      className={
                        show && item.id === long
                          ? style.contact_accordion_desc_active
                          : style.contact_accordion_desc
                      }
                    >
                      {item.description}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
        <div className={style.car_wash_options_footer}>
          {!props?.editData && (
            <Form.Item>
              <Button
                type="primary"
                onClick={skipToMyYard}
                style={{
                  background: "#38B6FF",
                  border: "none",
                  marginRight: 10,
                }}
              >
                Skip To My Yard
              </Button>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#38B6FF", border: "none" }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
export default CarWashOptions
