import React, { Component, useState, useEffect, useRef } from "react"
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
} from "antd"
import moment from "moment"
import { UploadOutlined, PlusOutlined } from "@ant-design/icons"
import AppLayout from "../../components/layout"
import "./cardropoff.css"
import AddCustomer from "../customer/addCustomer"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { CustomerList } from "../../actions/customerAction"
import { filter, get, values } from "lodash"
import { IndustryType, RAIL_CAR } from "../../utils/helper"
import InboundInspection from "../../components/InspectionBound"
import CarWashOptions from "../../components/CarWashOptions"
import API from "../../api"
const CarDropOff = props => {
  const {
    equipmentCheckin,
    getRailService,
    assetSearch,
    ticketDetailsUpdate,
  } = API
  const dispatch = useDispatch()
  const customerList = useSelector(state => state?.customer?.customerList)
  const [isLoading, setIsLoading] = useState(true)

  const [form] = Form.useForm()
  const [customer, setCustomer] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [details, setDetails] = useState({})
  const [selctedCarType, setSelectedCarType] = useState("")
  const [editData, setEditData] = useState()

  const [service, setService] = useState([])
  const [option, setOption] = useState([])
  const [assets, setAssets] = useState(null)
  const [assetId, setAssetId] = useState(null)
  const [customerName, setCustomerName] = useState(null)
  const [showRemarkInput, setShowRemarkInput] = useState(false)

  const callback = msg => {
    console.log(msg)
  }
  const getData = async () => {
    try {
      const res = await getRailService()
      setService(res?.data?.data)
    } catch (e) {}
  }
  useEffect(() => {
    getData()
  }, [])
  /**
   useEffect(() => {
    if (IndustryType() == RAIL_CAR) {
      setIsLoading(false)
    } else {
      // navigate("/dashboard")
      window.location.href = "/dashboard"
    }
  }, [])
   */
  useEffect(() => {
    dispatch(CustomerList(callback))
    //setEditData(typeof window !== undefined && props?.location?.state?.data)
  }, [])
  useEffect(() => {
    setIsLoading(false)
  }, [])
  useEffect(() => {
    if (props?.location?.state?.data) {
      console.log(props?.location?.state?.data)
      const d = props?.location?.state?.data
      setSelectedCarType(d?.equipment_type_id?.param_value)

      setEditData(props?.location?.state?.data)
      const liningType = props?.location?.state?.sendData?.find(
        item => item?.field_name == "int_lining_type"
      )
      const liningRemarks = props?.location?.state?.sendData?.find(
        item => item?.field_name == "lining_remakrs"
      )
      if (liningType?.field_value == "Other") {
        setShowRemarkInput(true)
      }

      form.setFieldsValue({
        equipment_type: d?.equipment_type_id?.param_value,
        gate_type: parseInt(d?.gateType) || null,
        ...(liningRemarks && { lining_remakrs: liningRemarks?.field_value }),
      })
    }
  }, [customer])
  useEffect(() => {
    if (customerList?.length > 0) {
      const data = customerList.map(obj => {
        return {
          value: obj.clients_cust_id,
          label: obj.name,
        }
      })
      setCustomer(data)
    }
  }, [customerList])
  useEffect(() => {
    if (props?.location?.state?.data) {
      const d = props?.location?.state?.data

      form.setFieldsValue({
        client_cust_id: parseInt(d?.client_cust_id),
      })
    }
  }, [customer])
  const onChangeCarDrpOff = value => {
    console.log(`selected ${value}`)
  }
  const onChangeCarDrpOffCarType = value => {
    /* navigate("/inbound-inspection", {
      state: {
        value: value,
      },
    })**/
    setSelectedCarType(value)
    console.log(value)
  }
  const onChange = (date, dateString) => {
    console.log(date, dateString)
  }
  const onSearchCarDrpOff = value => {
    console.log(value)
  }
  const filterOptionCarDrpOff = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  const onFinishCarDropOff = values => {
    console.log("Success:", values)
    if (props?.location?.state?.submit) {
      ticketEditHandler(false)
    }
    /*navigate("/inbound-inspection", {
      state: {
        custom: true,
        clients_cust_id: 1,
        title: "Car Drop-Off",
        newData: { ...props },
      },
    })**/
    values["blt_date"] = moment(values.blt_date).format("MM-DD-YYYY")
    values["dt_applied"] = moment(values.dt_applied).format("MM-DD-YYYY")
    values["inspection_date"] = moment(values.inspection_date).format(
      "MM-DD-YYYY"
    )
    setDetails(values)
    setActiveTab(1)
  }
  const ticketEditHandler = (isNavigate = true) => {
    const customer = form.getFieldValue("client_cust_id")
    const equipment_no = form.getFieldValue("equipment_no")
    const equipment_type = form.getFieldValue("equipment_type")
    const location = form.getFieldValue("location")
    const track = form.getFieldValue("track")
    const spot = form.getFieldValue("spot")
    const loadLimit = form.getFieldValue("load_limit")
    const lightWeight = form.getFieldValue("light_weight")
    const cu_ft = form.getFieldValue("cu_ft")
    const blt_date = form.getFieldValue("blt_date")
    const int_lining_type = form.getFieldValue("int_lining_type")

    const dt_applied = form.getFieldValue("dt_applied")
    const product_type = form.getFieldValue("product_type")
    const gate_type = form.getFieldValue("gate_type")
    const values = form.getFieldValue()
    const keyValues = Object.keys(form.getFieldValue())
    const editValues = []

    keyValues.forEach(item => {
      const filteredData =
        props?.location?.state?.sendData?.find(e => e?.field_name === item) ||
        null

      if (filteredData) {
        const itemData = {
          ...(filteredData?.id && { id: filteredData?.id }),
          key: item,
          value: values[item],
        }
        editValues.push(itemData)
      } else {
        if (values[item]) {
          const itemData = {
            key: item,
            value: values[item],
          }
          editValues.push(itemData)
        }
      }
    })
    const product_types = {
      id: editData.product_typeId ? editData.product_typeId : "",
      key: "product_type",
      value: product_type,
    }
    const dt_applieds = {
      id: editData.dt_appliedId ? editData.dt_appliedId : "",
      key: "dt_applied",
      value: dt_applied,
    }
    const locations = {
      id: editData.locationId ? editData.locationId : "",
      key: "location",
      value: location,
    }
    const tracks = {
      id: editData.trackId ? editData.trackId : "",
      key: "track",
      value: track,
    }
    const spots = {
      id: editData.spotId ? editData.spotId : "",
      key: "spot",
      value: spot,
    }
    const loadLimits = {
      id: editData.loadLimitId ? editData.loadLimitId : "",
      key: "load_limit",
      value: loadLimit,
    }
    const lightWeights = {
      id: editData.lightWeightId ? editData.lightWeightId : "",
      key: "light_weight",
      value: lightWeight,
    }
    const cu_fts = {
      id: editData.cu_ftId ? editData.cu_ftId : "",
      key: "cu_ft",
      value: cu_ft,
    }
    const blt_dates = {
      id: editData.bltDateId ? editData.bltDateId : "",
      key: "blt_date",
      value: blt_date,
    }
    const int_lining_types = {
      id: editData.int_lining_typeId ? editData.int_lining_typeId : "",
      key: "int_lining_type",
      value: int_lining_type,
    }

    const gate_types = {
      id: editData.gateTypeId ? editData.gateTypeId : "",
      key: "gate_type",
      value: gate_type,
    }
    // customer:'maddyyy'
    const val = {
      ...editData,
      client_cust_id: customer,
      equipment_no: equipment_no,
      equipment_type: equipment_type,
      txns_details: editValues,
      asset_txns_id: editData.asset_txns_id,
    }
    ticketDetailsUpdate(val)
      .then(response => {
        if (response.data.success) {
          if (isNavigate) {
            navigate(`/ticketdetails/${editData?.ticket_id}`)
          }
        }
      })
      .catch(err => {
        message.error({
          content: `${err?.response?.data.message}`,
          duration: 4,
        })
      })
  }

  const onFinishFailedCarDropOff = errorInfo => {
    console.log("Failed:", errorInfo)
  }
  const getTitle = () => {
    const title = {
      0: "Car Spotted",
      1: "Inspection",
      2: "Car Wash Options",
    }
    return title[activeTab]
  }
  const getCarType = () => {
    const data =
      typeof window !== "undefined" && JSON.parse(localStorage.getItem("data"))

    if (IndustryType() == RAIL_CAR) {
      const carType = data?.TabParams?.filter(
        item => item?.param_key == "car_type"
      )?.map(item => {
        return {
          value: item?.param_value,
          label: item?.param_name,
        }
      })
      return carType
    }
  }
  const getGateType = () => {
    const data =
      typeof window !== "undefined" && JSON.parse(localStorage.getItem("data"))
    if (IndustryType() == RAIL_CAR) {
      /* const filteredData = data?.TabParams?.filter(
        item => item?.client_id == data?.client_id
      )**/
      const gateType = data?.TabParams?.filter(
        item => item?.param_key == "gate_type"
      )?.map(item => {
        return {
          value: item?.param_value,
          label: item?.param_name,
        }
      })
      return gateType
    }
  }

  const onSubmit = (callApi = false, formDetails, skipToMyYard = false) => {
    if (callApi) {
      const data = {
        ...details,
        ...formDetails,
        ...(assetId && { asset_id: assetId }),
      }
      equipmentCheckin(data)
        .then(res => {
          if (!res?.data?.success) {
            message.error(res?.data?.message)
          }
          if (res?.data?.success) {
            message.success(res?.data?.message)
            if (skipToMyYard) {
              navigate("/yard/home")
            } else {
              navigate("/dashboard")
            }
          }
        })
        .catch(err => console.log(err))
    } else {
      setDetails({ ...details, ...formDetails })
      setActiveTab(2)
    }
  }
  const onChangeCustomer = value => {
    if (customerName !== value) {
      form.setFieldsValue({
        equipment_no: "",
        equipment_type: null,
      })
      setAssetId(null)
    }
    setCustomerName(value)
    assetSearch(value)
      .then(res => {
        if (res?.data?.success) {
          setAssets(res?.data?.data)
          const option = res?.data?.data?.map(item => {
            return {
              label: item?.equipment_no,
              value: item?.asset_id,
            }
          })

          setOption(option)
        }
      })
      .catch(err => console.log(err))
  }
  const onSearch = e => {
    const filterData = assets?.filter(item => item?.equipment_no == e)

    if (filterData?.length == 0) {
      setAssetId(null)
      form.setFieldsValue({
        // equipment_no: filterData[0]?.equipment_no,
        equipment_type: null,
      })
    }
    if (filterData?.length == 1) {
      form.setFieldsValue({
        equipment_no: filterData[0]?.equipment_no,
        equipment_type: filterData[0]["equipment_type_details.param_value"],
      })
      setAssetId(e)
    }
  }
  const onSelect = e => {
    const filterData = assets?.filter(item => item?.asset_id == e)

    if (filterData?.length == 1) {
      setSelectedCarType(filterData[0]["equipment_type_details.param_value"])
      form.setFieldsValue({
        equipment_no: filterData[0]?.equipment_no,
        equipment_type: filterData[0]["equipment_type_details.param_value"],
      })
      setAssetId(e)
    }
  }

  if (isLoading)
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    )

  return (
    <AppLayout>
      <div className="cardropoff_section">
        <div className="cardrop_header">
          <div className="cardrop_head_1">{getTitle()}</div>
          {/* <div className="cardrop_head_2">
            <span>{pagePath}</span>
            {">" + " " + newProps.title}
          </div> */}
        </div>
        {activeTab == 0 && (
          <Form
            className="car_drop_form"
            name="basic"
            onFinish={onFinishCarDropOff}
            onFinishFailed={onFinishFailedCarDropOff}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <div className="car_drep_off_add_customer">
              <Form.Item
                className="car_form_item"
                label="Customer Name"
                name="client_cust_id"
                rules={[
                  {
                    required: true,
                    message: "Please select customer name!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Customer Name"
                  optionFilterProp="children"
                  onChange={onChangeCustomer}
                  //onSearch={onSearchCarDrpOff}
                  filterOption={filterOptionCarDrpOff}
                  options={customer}
                  // defaultValue={editData?.customer ? editData?.customer : null}
                />
              </Form.Item>
              <div className="car_drep_off_add_customer_us">
                <AddCustomer
                  updateCustomerApi={() => dispatch(CustomerList(callback))}
                  equipmentcheckin={true}
                  Button={
                    <>
                      <span style={{ marginRight: "7px" }}>
                        <PlusOutlined />
                      </span>
                      <span>Add New Customer</span>
                    </>
                  }
                  title="ADD CUSTOMER"
                  // disabled={disableInput()}
                  custom={true}
                  className="wash_po_add_edit_btn"
                />
              </div>
            </div>
            <Form.Item
              className="car_form_item"
              label="Car No."
              name="equipment_no"
              initialValue={
                editData?.equipment_no ? editData?.equipment_no : ""
              }
              rules={[
                {
                  required: true,
                  message: "Please input your car no.!",
                },
              ]}
            >
              <AutoComplete
                placeholder="Enter Here"
                options={option}
                onSearch={onSearch}
                onSelect={onSelect}
              />
            </Form.Item>

            <Form.Item
              className="car_form_item"
              label="Car Type"
              name="equipment_type"
              rules={[
                {
                  required: true,
                  message: "Please select car type!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Car Type"
                optionFilterProp="children"
                onChange={onChangeCarDrpOffCarType}
                onSearch={onSearchCarDrpOff}
                filterOption={filterOptionCarDrpOff}
                options={getCarType()}
              />
            </Form.Item>

            <Form.Item
              className="car_form_item"
              label="Location"
              name="location"
              initialValue={editData?.location ? editData?.location : ""}
              rules={[
                {
                  required: true,
                  message: "Please input your location!",
                },
              ]}
            >
              <Input placeholder="Enter Location" />
            </Form.Item>
            <Form.Item
              className="car_form_item"
              label="Track"
              name="track"
              initialValue={editData?.track ? editData?.track : ""}
              rules={[
                {
                  required: true,
                  message: "Please input your Track no!",
                },
              ]}
            >
              <Input placeholder="Enter Track No" />
            </Form.Item>
            <Form.Item
              className="car_form_item"
              label="Spot"
              name="spot"
              initialValue={editData?.spot ? editData?.spot : ""}
              rules={[
                {
                  //required: true,
                  message: "Please input your spot details!",
                },
              ]}
            >
              <Input placeholder="Enter Spot Details" />
            </Form.Item>
            <div className="cardrop_carInfo">
              <p>Car Information</p>
              <div></div>
            </div>
            <Form.Item
              className="car_form_item"
              label="Load Limit"
              name="load_limit"
              initialValue={editData?.loadLimit ? editData?.loadLimit : ""}
              rules={[
                {
                  required: true,
                  message: "Please input your Load Limit!",
                },
              ]}
            >
              <Input placeholder="eg. 100000" />
            </Form.Item>
            <Form.Item
              className="car_form_item"
              label="Light Weight"
              name="light_weight"
              initialValue={editData?.lightWeight ? editData?.lightWeight : ""}
              rules={[
                {
                  required: true,
                  message: "Please input your Light Weight!",
                },
              ]}
            >
              <Input placeholder="eg. 10000" />
            </Form.Item>
            <Form.Item
              className="car_form_item"
              label={selctedCarType == 26010 ? "CU FT" : "Capacity"}
              name="cu_ft"
              initialValue={editData?.cu_ft ? editData?.cu_ft : ""}
              rules={[
                {
                  required: true,
                  message: "Please input your Capacity!",
                },
              ]}
            >
              <Input placeholder="eg. 1000" />
            </Form.Item>
            <Form.Item
              className="car_form_item"
              label="BLT Date"
              name="blt_date"
              rules={[
                {
                  required: editData?.bltDate ? false : true,
                  message: "Please input your BLT Date!",
                },
              ]}
            >
              {editData?.bltDate ? (
                <DatePicker
                  picker="month"
                  onChange={onChange}
                  size="large"
                  style={{ width: "100%", border: "1px solid #8B8FAE" }}
                  format={"MM/YYYY"}
                  defaultValue={moment(
                    editData?.bltDate ? editData?.bltDate : ""
                  )}
                />
              ) : (
                <DatePicker
                  picker="month"
                  format={"MM/YYYY"}
                  onChange={onChange}
                  size="large"
                  style={{ width: "100%", border: "1px solid #8B8FAE" }}
                />
              )}
            </Form.Item>
            <Form.Item
              className="car_form_item"
              label="INT Lining Type"
              name="int_lining_type"
              initialValue={
                editData?.int_lining_type ? editData?.int_lining_type : null
              }
              rules={[
                {
                  required: true,
                  message: "Please Select Your INT Lining type!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Lining Type"
                optionFilterProp="children"
                onChange={e => {
                  setShowRemarkInput(e == "Other")
                }}
                onSearch={onSearchCarDrpOff}
                filterOption={filterOptionCarDrpOff}
                options={[
                  {
                    label: "Hempel 37300",
                    value: "Hempel 37300",
                  },
                  {
                    label: "Carboline 992",
                    value: "Carboline 992",
                  },
                  {
                    label: "Other",
                    value: "Other",
                  },
                ]}
              />
            </Form.Item>
            {showRemarkInput && (
              <Form.Item
                className="car_form_item"
                label="Remakrs"
                name="lining_remakrs"
                rules={[
                  {
                    required: true,
                    message: "Fill This Value",
                  },
                ]}
              >
                <Input placeholder="eg. AL" />
              </Form.Item>
            )}
            <Form.Item
              className="car_form_item"
              label="DT Applied"
              name="dt_applied"
              rules={[
                {
                  required: editData?.dt_applied ? false : true,
                  message: "Please input your DT Applied!",
                },
              ]}
            >
              {editData?.dt_applied ? (
                <DatePicker
                  picker="month"
                  onChange={onChange}
                  size="large"
                  style={{ width: "100%", border: "1px solid #8B8FAE" }}
                  format={"MM/YYYY"}
                  defaultValue={moment(
                    editData?.dt_applied ? editData?.dt_applied : ""
                  )}
                />
              ) : (
                <DatePicker
                  onChange={onChange}
                  picker="month"
                  format={"MM/YYYY"}
                  size="large"
                  style={{ width: "100%", border: "1px solid #8B8FAE" }}
                />
              )}
            </Form.Item>
            {selctedCarType == 26010 && (
              <Form.Item
                className="car_form_item"
                label="Gate Type"
                name="gate_type"
                //initialValue={editData?.gate_type ? editData?.gate_type : null}
                rules={[
                  {
                    required: true,
                    message: "Please select gate type!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Gate Type"
                  optionFilterProp="children"
                  onChange={onChangeCarDrpOff}
                  onSearch={onSearchCarDrpOff}
                  filterOption={filterOptionCarDrpOff}
                  options={getGateType()}
                />
              </Form.Item>
            )}
            <Form.Item
              className="car_form_item"
              label="Product Type"
              name="product_type"
              initialValue={
                editData?.product_type ? editData?.product_type : ""
              }
              rules={[
                {
                  required: true,
                  message: "Please Select Product Type!",
                },
              ]}
            >
              <Input placeholder="Enter Product Type" />
            </Form.Item>
            {props?.location?.state?.submit ? (
              <div className="edit_ticket_forms">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100px" }}
                  >
                    Next
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={ticketEditHandler}
                    type="primary"
                    style={{ width: "100px", marginLeft: "15px" }}
                  >
                    submit
                  </Button>
                </Form.Item>
              </div>
            ) : (
              <Form.Item className="car_drop_submit">
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            )}
          </Form>
        )}
        {activeTab == 1 && (
          <InboundInspection
            isHopperCar={selctedCarType == 26010}
            changeTab={() => setActiveTab(2)}
            onSubmit={onSubmit}
            editData={editData}
            backFormData={details}
            sendData={props?.location?.state?.sendData}
          />
        )}
        {activeTab == 2 && (
          <CarWashOptions
            service={service}
            onSubmit={onSubmit}
            editData={editData}
            sendData={props?.location?.state?.sendData}
          />
        )}
      </div>
    </AppLayout>
  )
}

export default CarDropOff
