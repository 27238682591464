import React, { useState, useRef, useEffect } from "react"
import { AppLayout } from "../../components/layout"
import style from "./inboundInspection.module.css"
import Arrow from "../../assets/svg/down_arrow_icon.svg"
import { navigate } from "gatsby"
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
} from "antd"
import ExtInspection from "../ExitInspection/ExtInspection"
import Expandable from "../Expandeble/Expandable"
import CarBodyDefects from "../CarBodyDefects/CarBodyDefects"
import GateDefects from "../GateDefects/GateDefects"
import CarTopDefects from "../CarTopDefects/CarTopDefects"
import InteriorInspection from "../InterirorInspection/InteriorInspection"
import MechanicalInspection from "../MachenicalInspection/MechanicalInspection"
import TankCarInspection from "../TankCarInspection/TankCarInspection"
import API from "../../api"
import moment from "moment"
const InboundInspection = props => {
  const { ticketDetailsUpdate } = API
  const { changeTab } = props
  const [form] = Form.useForm()
  const [show, setShow] = useState(props?.isHopperCar)
  const [long, setLong] = useState(1)
  const accordionHandler = item => {
    setLong(item.id)
    item.id === long ? setShow(!show) : setShow(true)
  }
  const data = props?.isHopperCar
    ? [
        {
          id: 1,
          title: "External Inspection",
          description: <ExtInspection formValues={props?.sendData} />,
        },
        {
          id: 2,
          title: "Safety appliances defects",
          description: <Expandable formValues={props?.sendData} />,
        },
        {
          id: 3,
          title: "Car Body Defects",
          description: <CarBodyDefects formValues={props?.sendData} />,
        },
        {
          id: 4,
          title: "Gate Defects",
          description: <GateDefects formValues={props?.sendData} />,
        },
        {
          id: 5,
          title: "Car Top Defects",
          description: <CarTopDefects formValues={props?.sendData} />,
        },
        {
          id: 6,
          title: "Interior Inspection",
          description: <InteriorInspection formValues={props?.sendData} />,
        },
        {
          id: 7,
          title: "Mechanical Inspection",
          description: <MechanicalInspection formValues={props?.sendData} />,
        },
      ]
    : [
        {
          id: 1,
          title: "Tank Car Inspection",
          description: <TankCarInspection formValues={props?.sendData} />,
        },
      ]
  const onFinishCarDropOffInspection = values => {
    console.log("Success:", values)

    /* navigate("/car-wash-options", {
      state: {
        custom: true,
        clients_cust_id: 1,
        title: "Car Drop-Off",
        newData: { ...props },
      },
    })**/
    props?.editData && ticketEditHandler(false)
    console.log(values)
    const item = {
      ...values,
      inspection_date: moment(values?.inspection_date)?.format("MM-DD-YYYY"),
    }
    if (props.isHopperCar) {
      props.onSubmit(false, item)
    } else {
      //call Api
      props.onSubmit(false, item)
    }
  }
  const onFinishFailedCarDropOffInspection = errorInfo => {
    console.log("Failed:", errorInfo)
  }
  const onChangeInspDate = e => {
    console.log(e)
  }
  useEffect(() => {
    if (props?.editData) {
      const fileds = props?.isHopperCar
        ? [
            "exterior_paint_condition",
            "saftey_defects_other",
            "car_body_defects_other",
            "gate_defects_other",
            "top_defects_other",
            "interior_inspection_other",
            "external_inspection_other",
            "machenical_inspection_other",
            "exterior_paint_defects",
            "extendable_safety_appliances_ladders_notes",
            "secondLocation",
            "exterior_paint_notes",
            "exterior_paint_remark",
            "exterior_paint_new_car_remark",
            "extendable_safety_appliances_ladders",
            "extend_location",
            "extendable_safety_appliances_handholds",
            "handholds_location",
            "extendable_safety_appliances_handholds_notes",
            "extendable_safety_appliances_walkboards",
            "extendable_safety_appliances_crossover_platforms",
            "extendable_safety_appliances_walkboard_notes",
            "crossOver_location",
            "crossOver_notes",
            "walkboards_location",
            "car_body_defects_ends",
            "car_body_defects_ends_location",
            "car_body_defects_ends_notes",
            "car_body_defects_side_sheets",
            "car_body_defects_slope_sheets",
            "gate_defects_cap",
            "gate_defects_cap_location",
            "gate_defects_cap_qty",
            "gate_defects_cap_notes",
            "gate_defects_nozzle",
            "nozle_location",
            "nozle_qty",
            "nozle_notes",
            "gate_defects_handle",
            "handle_location",
            "handle_qty",
            "handle_notes",
            "gate_defects_outlet_nozzle",
            "gate_defects_location",
            "quantity",
            "gate_defects_notes",
            "gate_defects_end_adapter",
            "gate_defects_end_adapter_notes",
            "gate_defects_end_location",
            "gate_defects_end_adapter_qty",
            "gate_defects_lock_assembly",
            "gate_defects_celvis_pin",
            "gate_defects_gate_gasket",
            "gate_defects_gate_hardware",
            "gate_defects_car_roof",
            "car_top_defects_non_vented_hatch",
            "non_vented_location",
            "non_vented_qty",
            "non_vented_notes",
            "car_top_defects_location",
            "car_top_defects_notes",
            "car_top_defects_vented_hatch_cover",
            "vented_hatch_cover_location",
            "vented_hatch_qty",
            "vented_hatch_notes",
            "Notes",
            "car_top_defects_hatch_gasket",
            "hatch_gasket_location",
            "hatch_gasket_qty",
            "hatch_gasket_notes",
            "car_top_defects_hatch_latch",
            "hatch_latch_location",
            "hatch_latch_qty",
            "hatch_latch_notes",
            "car_top_defects_inner_screen",
            "inner_screen_location",
            "inner_scrren_qty",
            "inner_scrren_notes",
            "car_top_defects_outer_screen",
            "outer_screen_location",
            "outer_scrren_qty",
            "outer_scrren_notes",
            "car_top_defects_hatch_arm",
            "hatch_alaram_location",
            "hatch_alaram_qty",
            "hatch_alaram_notes",
            "interior_paint_condition",
            "interior_paint_defects",
            "interior_location",
            "interior_notes",
            "interior_partition_weld",
            "partition_location",
            "partition_notes",
            "defect_notes",
            "mechanical_inspection",
            "brakes",
            "break_shoe_location",
            "break_shoe_qty",
            "break_shoe_notes",
            "couplers",
            "cupler_shoe_location",
            "cupler_shoe_qty",
            "cupler_shoe_notes",
            "Hoses",
            "hoses_location",
            "hoses_qty",
            "hoses_notes",
            "mechanical_inspection_notes",
            "inspector_name",
            "outlet_nozle",
            "outlet_location",
            "outlet_qty",
            "outlet_notes",
            "end_adapter",
            "end_adapter_location",
            "end_adapter_qty",
            "end_adapter_notes",
            "lock_assembly",
            "lock_assembly_location",
            "lock_assembly_qty",
            "lock_assembly_notes",
            "clevis_pin",
            "clevis_pin_location",
            "clevis_pin_qty",
            "clevis_pin_notes",
            "gate_gasket",
            "gate_gasket_location",
            "gate_gasket_qty",
            "gate_gasket_notes",
            "ext_paint_condition_remarks",
          ]
        : [
            "interior_paint_condition",
            "many_way_present",
            "bolts_present",
            "graffiti_present",
            "bov_present",
            "belly_cap_present",
            "chain_present",
            "ladder",
            "tank_location",
            "qty",
            "describe_defect",
            "safty_railing",
            "notes",
            "walk_bords",
            "valve_leaver",
            "saftey_dome",
            "inspector_name",
            "gate_hardware",
            "gate_hardware_location",
            "gate_hardware_qty",
            "gate_hardware_notes",
            "int_remakrs",
            "tank_car_lining_condition_remarks",
          ]

      const sendData = props?.sendData
      sendData?.length > 0 &&
        sendData.forEach(item => {
          //debugger
          if (fileds?.includes(item?.field_name)) {
            form.setFieldsValue({
              [item.field_name]: item.field_value,
            })
          }
        })
    }
  }, [])
  const skipYardHnadler = () => {}

  const ticketEditHandler = (isNavigate = true) => {
    const inspection_date = form.getFieldValue("inspection_date")
    const inspector_name = form.getFieldValue("inspector_name")
    const filedsData = form?.getFieldsValue()
    const fields = Object.keys(form?.getFieldsValue())
    const sendData = props?.sendData || []
    const editData = []
    /**sendData?.length > 0 &&
      sendData.forEach(items => {
        //debugger
        if (fields?.includes(items?.field_name)) {
          const item = {
            ...(items?.id && { id: items?.id }),
            key: items?.field_name,
            value: filedsData[items?.field_name],
          }

          editData.push(item)
        }
      })**/
    fields.forEach(item => {
      const filteredData = sendData?.find(e => e?.field_name === item) || null
      if (filteredData) {
        const itemData = {
          ...(filteredData?.id && { id: filteredData?.id }),
          key: item,
          value: filedsData[item],
        }
        editData.push(itemData)
      } else {
        if (filedsData[item]) {
          const itemData = {
            key: item,
            value: filedsData[item],
          }
          editData.push(itemData)
        }
      }
    })

    // customer:'maddyyy'
    const val = {
      ...props?.editData,
      client_cust_id: props?.backFormData.client_cust_id,
      equipment_no: props?.backFormData.equipment_no,
      equipment_type: props?.backFormData.equipment_type,
      txns_details: editData,
      asset_txns_id: props?.editData.asset_txns_id,
    }
    ticketDetailsUpdate(val)
      .then(response => {
        if (response.data.success) {
          if (isNavigate) {
            navigate(`/ticketdetails/${props?.editData?.ticket_id}`)
          }
        }
      })
      .catch(err => {
        message.error({
          content: `${err?.response?.data.message}`,
          duration: 4,
        })
      })
  }
  const skipToMyYard = e => {
    e.preventDefault()
    const inspection_date = form.getFieldValue("inspection_date")
    const inspector_name = form.getFieldValue("inspector_name")
    if (!inspection_date) {
      return message.error("Select Inspection Date")
    }
    if (!inspector_name) {
      return message.error("Enter Inspector Name")
    }

    const filedsData = form?.getFieldsValue()
    props.onSubmit(true, filedsData, true)
  }

  return (
    <div className={style.inspection_section}>
      <Form
        className="car_drop_form"
        name="basic"
        onFinish={onFinishCarDropOffInspection}
        onFinishFailed={onFinishFailedCarDropOffInspection}
        autoComplete="off"
        layout={"vertical"}
        form={form}
        style={{
          maxWidth: "100%",
          background: "#F6F6F6",
        }}
      >
        <div className={style.inbound_inspection_date}>
          <Form.Item
            className="car_form_item"
            label="Insp. Date"
            name="inspection_date"
            style={{ marginRight: "3%" }}
            rules={[
              {
                required: true,
                message: "Please input your Inspection Date!",
              },
            ]}
          >
            <DatePicker
              onChange={onChangeInspDate}
              size="large"
              style={{ width: "100%", border: "1px solid #8B8FAE" }}
            />
          </Form.Item>
          <Form.Item
            className="car_form_item"
            label="Inspector"
            name="inspector_name"
            onChange={e => {
              e.target.value = e.target.value.replace(/^\s+/g, "")
              form.setFieldsValue({
                inspector_name: e.target.value,
              })
            }}
            initialValue={
              props?.editData?.inspector ? props?.editData?.inspector : ""
            }
            rules={[
              {
                required: true,
                message: "Please input your inspector name!",
              },
            ]}
          >
            <Input placeholder="Enter Inspector Name" />
          </Form.Item>
        </div>
        <div className={style.contact_section}>
          <div className={style.contact_container}>
            {data.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={
                      show && item.id === long
                        ? style.contact_accordion_active
                        : props.isHopperCar
                        ? style.contact_accordion
                        : style.contact_accordion_active
                    }
                  >
                    <div
                      className={style.contact_accordion_head}
                      onClick={
                        props?.isHopperCar ? () => accordionHandler(item) : ""
                      }
                      style={{
                        height:
                          show && item.id === long
                            ? "7vh"
                            : props.isHopperCar
                            ? "10vh"
                            : "7vh",
                        borderBottom:
                          show && item.id === long
                            ? "1px solid #8B8FAE"
                            : props.isHopperCar
                            ? ""
                            : "1px solid #8B8FAE",
                      }}
                    >
                      <h3>{item.title}</h3>
                      {props?.isHopperCar ? (
                        <img
                          src={Arrow}
                          alt="icon forword"
                          style={{
                            transform:
                              show && item.id === long ? "rotate(180deg)" : "",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className={
                        show && item.id === long
                          ? style.contact_accordion_desc_active
                          : props.isHopperCar
                          ? style.contact_accordion_desc
                          : style.contact_accordion_desc_active
                      }
                    >
                      {item.description}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
        <div className={style.inspection_skip}>
          {props?.editData ? (
            <>
              {/**  <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100px" }}
                >
                  Next
                </Button>
              </Form.Item> */}
              <Form.Item>
                <Button
                  onClick={ticketEditHandler}
                  type="primary"
                  style={{ width: "100px", marginLeft: "15px" }}
                >
                  submit
                </Button>
              </Form.Item>
            </>
          ) : (
            <>
              <Button type="primary" onClick={skipToMyYard}>
                skip to my yard
              </Button>

              <Form.Item>
                <Button
                  //onClick={() => onSubmitClick()}
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "20px", width: "100px" }}
                >
                  Next
                </Button>
              </Form.Item>
            </>
          )}
        </div>
      </Form>
    </div>
  )
}

export default InboundInspection
